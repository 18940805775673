var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-auto pa-0",attrs:{"id":"gallery","fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0 main-modified"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pa-0 ma-0",staticStyle:{"height":"300px","filter":"grayscale(0.1)","background-repeat":"no-repeat","background-size":"cover"},style:({'background-image': 'url(' + require('../../assets/images/gallery/gallery-bg.jpg') + ')'})},[_c('v-col',{staticClass:"text-center ma-auto"},[_c('h1',{staticClass:"text-h4 custom-secondary--text text-uppercase",staticStyle:{"-webkit-text-stroke":"0.2px white"}},[_vm._v(_vm._s(_vm.$t("gallery")))])])],1),_c('v-row',[_c('v-col',{staticClass:"ma-auto",attrs:{"cols":"10","md":"10","lg":"8"}},[(_vm.gallery_loading)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('v-row',{staticClass:"px-8 ma-0",attrs:{"justify":"center"}},_vm._l((_vm.itemsperpage),function(i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"4","md":"4","lg":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"image"}})],1)}),1)],1):_vm._e(),(_vm.images.length==0 && !_vm.gallery_loading)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('v-row',{staticClass:"text-center pa-12 ma-auto my-7"},[_c('i',{staticClass:"ma-auto text-h4 text--secondary"},[_vm._v(_vm._s(_vm.$t("noresult"))+".")])])],1):_vm._e(),(_vm.images.length && !_vm.gallery_loading)?_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('v-row',{key:_vm.itemsperpage * _vm.page,staticClass:"px-8 ma-0",attrs:{"justify":"center"}},_vm._l((_vm.images.slice(
                  _vm.itemsperpage * (_vm.page - 1),
                  _vm.itemsperpage * _vm.page
                )),function(image,n){return _c('v-col',{key:n,attrs:{"cols":"12","sm":"4","md":"4","lg":"4"}},[_c('v-card',{staticClass:"mx-auto elevation-0"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-img',{staticClass:"image",attrs:{"src":require('../../assets/'+image.image),"alt":image.alt,"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[_c('v-fade-transition',[(hover)?_c('v-overlay',{staticStyle:{"cursor":"pointer"},attrs:{"absolute":"","color":"custom-accent"},on:{"click":function($event){return _vm.openFullscreen(image.id)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-arrow-expand")])],1):_vm._e()],1)],1)]}}],null,true)})],1)],1)}),1)],1):_vm._e(),_c('v-row',{staticClass:"pa-0 ma-0 mb-4"},[(Math.ceil(_vm.images.length / _vm.itemsperpage)>1)?_c('v-col',[_c('v-pagination',{attrs:{"length":Math.ceil(_vm.images.length / _vm.itemsperpage),"color":"custom-accent","total-visible":7,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1100px"},model:{value:(_vm.imagefullscreenoverlay),callback:function ($$v) {_vm.imagefullscreenoverlay=$$v},expression:"imagefullscreenoverlay"}},[_c('v-card',{staticClass:"custom-off-white pt-2 overflow-hidden"},[_c('v-tooltip',{attrs:{"left":"","color":"custom-secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"z-index":"3","top":"-1px","right":"-2px"},attrs:{"absolute":"","top":"","right":"","title":_vm.$t('close'),"icon":""},on:{"click":function($event){_vm.imagefullscreenoverlay = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-close-circle")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("close")))])]),_c('v-carousel',{attrs:{"hide-delimiters":""},model:{value:(_vm.index),callback:function ($$v) {_vm.index=$$v},expression:"index"}},_vm._l((_vm.images),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":require('../../assets/'+item.image),"contain":"","reverse-transition":"fade-transition","transition":"fade-transition"}})}),1),_c('v-slide-group',{attrs:{"center-active":"","mandatory":"","show-arrows":""},model:{value:(_vm.index),callback:function ($$v) {_vm.index=$$v},expression:"index"}},_vm._l((_vm.images),function(image){return _c('v-slide-item',{key:image.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var active = ref.active;
return [_c('v-card',{staticClass:"ma-4",class:active?'active-preview my-2':'preview',attrs:{"width":"100","height":"100"},on:{"click":function($event){return _vm.openFullscreen(image.id)}}},[_c('v-img',{attrs:{"src":require('../../assets/'+image.image),"alt":image.alt,"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)]}}],null,true)})}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }