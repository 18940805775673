<template>
  <v-container id="gallery" class="ma-auto pa-0" fluid>
    <v-row class="pa-0 ma-0 main-modified">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row class="pa-0 ma-0" :style="{'background-image': 'url(' + require('../../assets/images/gallery/gallery-bg.jpg') + ')'}" style="height:300px;filter: grayscale(0.1);background-repeat: no-repeat;background-size: cover;">
          <v-col class="text-center ma-auto">
            <h1 class="text-h4 custom-secondary--text text-uppercase" style="-webkit-text-stroke: 0.2px white;">{{
              $t("gallery")
            }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" md="10" lg="8" class="ma-auto">
            <transition name="fade" mode="out-in" v-if="gallery_loading">
              <v-row class="px-8 ma-0" justify="center">
                <v-col
                  v-for="i in itemsperpage"
                  :key="i"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                >
                  <v-skeleton-loader
                    type="image"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </transition>
            <transition name="fade" mode="out-in" v-if="images.length==0 && !gallery_loading">
              <v-row class="text-center pa-12 ma-auto my-7" ><i class="ma-auto text-h4 text--secondary">{{$t("noresult")}}.</i></v-row>
            </transition>
            <transition name="fade" mode="out-in" v-if="images.length && !gallery_loading">
              <v-row  class="px-8 ma-0" justify="center" :key="itemsperpage * page">
                <v-col
                  v-for="(image,n) in images.slice(
                    itemsperpage * (page - 1),
                    itemsperpage * page
                  )"
                  :key="n"
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                >
                  <v-card class="mx-auto elevation-0">
                    <v-hover>
                      <template v-slot:default="{ hover }">
                          <v-img
                            :src="require('../../assets/'+image.image)"
                            :alt="image.alt"
                            aspect-ratio="1"
                            class="image"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          <v-fade-transition>
                            <v-overlay @click="openFullscreen(image.id)" style="cursor:pointer"
                              v-if="hover"
                              absolute
                              color="custom-accent"
                            >
                              <v-icon large>mdi-arrow-expand</v-icon>
                            </v-overlay>
                          </v-fade-transition>
                        </v-img>
                      </template>
                    </v-hover>
                  </v-card>
                </v-col>
              </v-row>
            </transition>
            <v-row class="pa-0 ma-0 mb-4">
              <v-col v-if="Math.ceil(images.length / itemsperpage)>1">
                <v-pagination
                  v-model="page"
                  :length="Math.ceil(images.length / itemsperpage)"
                  color="custom-accent"
                  :total-visible="7"
                  circle
                ></v-pagination>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-dialog v-model="imagefullscreenoverlay" max-width="1100px">
          <v-card class="custom-off-white pt-2 overflow-hidden">
          <v-tooltip left color="custom-secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                absolute
                top
                right
                style="z-index: 3;top:-1px;right:-2px"
                v-bind="attrs"
                v-on="on"
                :title="$t('close')"
                @click="imagefullscreenoverlay = false"
                icon
              >
                <v-icon large>mdi-close-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("close") }}</span>
          </v-tooltip>
            <v-carousel v-model="index" hide-delimiters>
              <v-carousel-item
                v-for="(item,i) in images"
                :key="i"
                :src="require('../../assets/'+item.image)"
                contain
                reverse-transition="fade-transition"
                transition="fade-transition"
              ></v-carousel-item>
            </v-carousel>
            <v-slide-group
              v-model="index"
              center-active
              mandatory
              show-arrows
            >
              <v-slide-item
                v-for="image in images"
                :key="image.id" 
                v-slot="{ active }"
              >
                <v-card
                  class="ma-4"
                  :class="active?'active-preview my-2':'preview'"
                  width="100"
                  height="100"
                  @click="openFullscreen(image.id)"
                >
                  <v-img
                    :src="require('../../assets/'+image.image)"
                    :alt="image.alt"
                    aspect-ratio="1"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "Gallery",

  data() {
    return {
      index: 0,
      imagefullscreenoverlay: false,
      page: 1,
      itemsperpage: 9,
    };
  },
  components: {
  },
  async created() {
    await this.retrieveImages().then(()=>{
      this.images.reverse();
    });
  },
  computed: {
    ...mapGetters('gallery', {
      images:'getImages'
    }),
    ...mapState("gallery", {
      gallery_loading: "loading"
    }),
  },
  watch: {
    page(){
      this.$root.$emit('toTop');
    },
  },
  methods: {
    openFullscreen(id){
      this.index = id-1;
      this.imagefullscreenoverlay = true;
    },
    
    ...mapActions("gallery", ["addImage","retrieveImages"]),
  },
};
</script>
<style scoped>
@import url("../../assets/styles/gallery.css");
</style>